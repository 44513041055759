@import "../../../sassStyles/variables";

.dashboard-topbar {
  background: $secondaryColor8 !important;
  display: flex;
  padding-inline: 30px !important;
  height: 54px !important;

  .logo {
    flex-grow: 1;
    padding: 12px 0px;
    margin-top: -5px;
    img {
      cursor: pointer;
      width: 50px;
    }
  }

  .user-profile {
    padding-left: 10px;
    padding-right: 10px;

    .avatar {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0px;
    }
  }
  div {
    display: flex;
  }
}

.dashboard-topbar .ant-btn {
  border: none;
  height: 100%;
  border-radius: 0px;
  color: $whiteColor;
  background-color: $secondaryColor8;
  box-shadow: none;
  width: 50px;
  font-size: 16px;
  box-shadow: nul;
}
.dashboard-topbar .ant-btn:hover {
  background-color: $primaryColor2 !important;
  color: $secondaryColor !important;
}

// .user-avatar .ant-avatar {
//   background-color: $secondaryColor2;
//   color: $secondaryColor8;
// }

// .user-profile:hover,
// .user-profile-active {
//   background-color: $secondaryColor6;
// }

// .user-avatar-active .anticon-down {
//   transform: rotate(-180deg);
// }

// .user-avatar-dropdown {
//   top: 64px !important;
//   .ant-dropdown-menu {
//     border-radius: 0px 0px 6px 6px;
//   }
// }

.ant-badge .ant-scroll-number {
  top: 18px;
  right: 14px;
  background-color: $primaryColor;
  color: $secondaryColor8;
  box-shadow: 0 0 0 2px $secondaryColor8;
  min-width: 18px;
  height: 18px;
  font-size: 13px;
  font-weight: 500;
}

// .ant-badge::is(.dashboard-topbar .ant-btn:hover):hover {
//   background-color: $secondaryColor;
//   color: $primaryColor;
//   box-shadow: 0 0 0 2px #e6a701;
// }
