@import "../../../sassStyles/variables";

.import-result {
  min-width: 1300px;
  top: 4vh;
  padding-bottom: 0px;

  .categories-cell {
    display: table-cell;
    div {
      display: inline-block;
    }
  }

  .ant-table-cell {
    padding: 10px 4px !important;
    font-size: 13px;
  }
  .category-tag {
    font-size: 12px;
    font-weight: 400 !important;
    b {
      font-weight: 400;
    }
  }
  .text-center {
    text-align: center;
  }
  .ant-modal-body {
    min-height: 70vh;
  }

  .ant-modal-title {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .updated-value {
    color: $successColor;
    .flag {
      border: 1px solid $successColor;
      padding: 5px;
    }
    .ant-tag {
      color: $successColor;
    }
  }
  .link {
    text-align: right;
    margin-bottom: 3px;
  }
  .link:active {
    color: $warningColor;
  }
  .previous-value {
    color: $warningColor;
    .flag {
      border: 1px solid $warningColor;
      padding: 5px;
    }
    .ant-tag {
      color: $warningColor;
    }
  }
}
