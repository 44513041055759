@import "../../../../sassStyles/variables";

.website-status {
  margin-bottom: 20px;
  background-color: $whiteColor;
  border: 1px solid $secondaryColor2;

  .header {
    display: flex;
    padding: 10px;
    padding-top: 20px;
    margin: 0px;
    border-bottom: 1px solid $secondaryColor2;
    .heading {
      font-size: 20px;
      font-weight: 500;
      color: $textColor;
      flex-grow: 1;
    }
  }

  .content {
    padding: 0px;
  }
  .website-actions {
    .link {
      text-decoration: underline;
    }
  }
}
