@import "../../../sassStyles/variables";

.notification-item {
  width: 24rem;

  .main {
    display: flex;
    padding: 10px;
    .content {
      display: block;
      flex-grow: 1;

      .message {
        width: 18rem;
      }
      .link {
        color: $secondaryColor4;
        font-size: 14px;
        font-style: italic;
        text-decoration: underline;
      }
      .date {
        font-size: 11px;
        color: $textColor;
      }
    }
    .bullet {
      font-size: 8px !important;
      color: $primaryColor2;
      margin-right: 10px;
      margin-top: 8px;
    }

    .avatar {
    }
  }

  .main:hover {
    background-color: $secondaryColor;
  }
}

.read .main {
  margin-left: 20px;
}

.unread .main {
  background-color: $secondaryColor;
  font-weight: 500;
}

.notification-wrapper {
  background: $whiteColor;
  border-radius: 0px 0px 6px 6px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);

  .header {
    display: flex;
    padding: 10px 10px 15px 20px;

    h1 {
      font-size: 16px;
      font-weight: 500;
      color: $textColor2;
      flex-grow: 1;
    }
    h3 {
      font-size: 12px;
      font-weight: 400;
      margin-top: 5px;
      color: $primaryColor2;
    }

    .anticon {
      color: $primaryColor;
      margin-top: 8px;
      margin-right: 5px;
      font-size: 15px;
    }
  }

  .empty {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: $secondaryColor4;
    width: 24rem;
  }

  .mark-read {
    display: flex;
    color: $primaryColor;
  }
  .mark-read:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  .notification-action {
    padding: 10px 30px;
    text-align: center;
    color: $primaryColor2;
    font-size: 12px;
    font-weight: 400;
    .load-more {
      cursor: pointer;
    }
    .load-more:hover {
      text-decoration: underline;
    }

    .loading {
      display: flex;
      justify-content: center;
      align-items: center;
      .ant-spin {
        color: $primaryColor2;
        margin-right: 6px;
      }
      .anticon-loading {
        font-size: 16px;
      }
    }
  }
  .ant-dropdown-menu {
    box-shadow: none;
    padding: 0;
  }

  .ant-dropdown-menu-item {
    padding: 0 !important;
    box-shadow: none;
  }
  .ant-dropdown-menu-item:hover {
    background-color: $whiteColor;
  }
  .ant-divider-horizontal {
    margin: 0px;
    margin-right: 10px;
    margin-left: 26px;
    width: auto;
    min-width: auto;
  }
}
