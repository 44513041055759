@import "./variables";

// Breakpoints
@mixin smMinBreakPoint {
  @media (min-width: #{$screen-sm}) {
    @content;
  }
}

@mixin mdMinBreakPoint {
  @media (min-width: #{$screen-md}) {
    @content;
  }
}

@mixin lgMinBreakPoint {
  @media (min-width: #{$screen-lg}) {
    @content;
  }
}

@mixin xlMinBreakPoint {
  @media (min-width: #{$screen-xl}) {
    @content;
  }
}

@mixin xxlMinBreakPoint {
  @media (min-width: #{$screen-xxl}) {
    @content;
  }
}

@mixin customMinBreakPoint($size) {
  @media (min-width: $size+"px") {
    @content;
  }
}

@mixin customMaxBreakPoint($size) {
  @media (max-width: $size+"px") {
    @content;
  }
}
