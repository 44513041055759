@import "../../sassStyles/variables";

.validation-rules {
  height: calc(100vh - 54px);
  background-color: $secondaryColor;
  padding: 40px 20px;
  overflow-y: scroll;
  .title {
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 30px;
  }

  .fields {
    padding: 0px 20px;
    ul {
      list-style-type: disclosure-closed;
      padding-inline-start: 20px;
      margin: 0;
      margin-top: 10px;
      color: $textColor;
      font-size: 13px;
    }
    .example {
      color: $linkColor;
      font-size: 13px;
      font-weight: 500;
      font-style: italic;
    }
    b {
      font-size: 16px;
      font-weight: 500;
    }
  }

  .countries {
    margin-bottom: 60px;
    span {
      color: $textColor;
    }
  }
  .categories {
    margin-bottom: 60px;
    span {
      color: $textColor;
    }
  }
}
