@import "../../sassStyles/variables";

.order-view {
  .ant-breadcrumb {
    font-size: 14px;
  }

  .header {
    margin-top: 20px;
    h2 {
      font-size: 30px;
      font-weight: 400;
      margin-left: 0;
      color: $secondaryColor8;
      padding-bottom: 10px;
      margin-bottom: 0;
    }
  }

  .heading {
    font-size: 30px;
    font-weight: 400;
    margin-left: 0;
    padding-bottom: 10px;
    color: $secondaryColor8;
    margin-bottom: 0;
  }
}

.order-view .articles-list {
  margin: 20px 0px;

  .result-link {
    text-decoration: underline;
  }

  .link {
    color: $linkColor;
  }
  .price {
    color: $successColor2;
    font-weight: 600;
    font-size: 14px;
  }
  .cost {
    color: $linkColor2;
    font-weight: 600;
    font-size: 14px;
  }

  .ant-table {
    border: 0px solid $primaryColor;
    background-color: $whiteColor;
    border-radius: 0px;
    border-bottom: none;
  }
  .ant-table-wrapper .ant-table-thead > tr > th {
    background-color: $whiteColor;
  }
  .ant-table-wrapper .ant-table-thead > tr > th {
    border-color: $secondaryColor2;
    font-size: 12.5px;
    font-weight: 700;
    padding: 4px 16px !important;
    background-color: $secondaryColor;
    color: $textColor;
    border-right: 1px solid $secondaryColor;

    letter-spacing: 0.4px;
  }

  .ant-table-wrapper
    .ant-table:not(.ant-table-bordered)
    .ant-table-tbody
    > tr.ant-table-row:hover
    > td:first-child {
    border-radius: 0px;
  }

  .ant-table-wrapper
    .ant-table:not(.ant-table-bordered)
    .ant-table-tbody
    > tr.ant-table-row:hover
    > td:last-child {
    border-radius: 0px;
  }

  .ant-table-wrapper
    .ant-table:not(.ant-table-bordered)
    .ant-table-tbody
    > tr.ant-table-row:hover
    > td {
    border-color: $whiteColor;
    background-color: $secondaryColor;
  }
  .ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:first-child {
    border-start-start-radius: 0px;
  }
  .ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:last-child {
    border-start-end-radius: 0px;
  }

  .ant-table-wrapper
    .ant-table:not(.ant-table-bordered)
    .ant-table-tbody
    > tr
    > td {
    border-color: $secondaryColor2;
  }

  .ant-table-tbody {
    .ant-table-cell {
      padding: 4px 16px;
      font-size: 12.5px;
      font-weight: 400;
    }
  }
  .ant-table-wrapper .ant-table-thead > tr > th {
    padding: 10px 16px;
  }
}

.link-popover {
  .ant-descriptions.ant-descriptions-bordered .ant-descriptions-view {
    border: none;
  }
  .ant-descriptions.ant-descriptions-bordered .ant-descriptions-item-label {
    background-color: $whiteColor;
  }
  .link {
    color: $linkColor;
    font-size: 14px;
    cursor: pointer;
    text-decoration: underline;
  }
  .ant-popover-inner {
    min-width: 30rem;
  }

  .header {
    background-color: $secondaryColor !important;
    font-weight: 500;
    color: $secondaryColor10 !important;
    display: table-cell !important;
  }
}
