@import "../../../sassStyles/variables";

.website-filter {
  .ant-form-item {
    margin-bottom: 5px;
  }

  .ant-form-item-label {
    padding-bottom: 0px;
  }

  .ant-form-item-label > label {
    color: $secondaryColor8;
    font-size: 11px;
    height: 0px;
  }
  .ant-slider-horizontal {
    margin-bottom: 0px;
  }
  .actions {
    margin-top: 15px;
  }

  .ant-btn {
    min-width: 100px !important;
    height: 28px;
    font-size: 13px;
    border-radius: 4px;
    padding: 0px 12px;
  }

  .ant-slider-track {
    background-color: $secondaryColor4;
  }

  .ant-slider:hover .ant-slider-track {
    background-color: $secondaryColor6;
  }

  .ant-slider .ant-slider-handle::after {
    box-shadow: 0 0 0 2px $secondaryColor4;
  }
  .ant-slider {
    margin: 0px 5px;
  }

  .flag {
    font-size: 14px;
    margin-left: 1px;
    margin-right: 8px;
  }
  .ant-input-group {
    display: flex;
    height: 28px;
    :first-child {
      border-end-start-radius: 4px;
      border-start-start-radius: 4px;
    }
    :last-child {
      border-end-end-radius: 4px;
      border-start-end-radius: 4px;
    }
  }
}
