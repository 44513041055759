@import "./sassStyles/variables";
@import "./sassStyles/mixins";

// ::-webkit-scrollbar {
//   width: 6px;
//   height: 6px;
//   border-radius: 7px;
// }

// ::-webkit-scrollbar-thumb {
//   background: $secondaryColor6 !important;
//   border-radius: 22px;
//   height: 10px;
// }

// ::-webkit-scrollbar-button {
//   height: 100%;
// }

// ::-webkit-scrollbar-corner,
// ::-webkit-scrollbar-track {
//   border-radius: 7px;
//   margin: 20px;
//   background-color: $secondaryColor2;
// }

.ant-btn {
  border-radius: 4px;
  height: 28px;
  font-size: 13px;
  line-height: 20px;
  padding: 0px 12px;
}
.ant-btn-primary:hover {
  background-color: $secondaryColor9 !important;
}

.ant-btn-primary {
  background-color: $secondaryColor8 !important;
  letter-spacing: 0.5px;
  font-weight: 400;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 28px;
  line-height: 26px !important;
  border-radius: 4px;
}
.ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 26px;
}

.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  ):active
  .ant-select-selector {
  border-color: $secondaryColor4;
  border: none;
  box-shadow: none;
}
.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  ):focus
  .ant-select-selector {
  border-color: $secondaryColor4 !important;
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 10%);
}

.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  ):hover
  .ant-select-selector {
  border-color: $secondaryColor4 !important;
}

.ant-input:hover {
  border-color: $secondaryColor9;
}

.ant-input:focus {
  border-color: $secondaryColor9;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $secondaryColor9;
  border-color: $secondaryColor9;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled)
  .ant-checkbox-inner {
  border-color: $secondaryColor8;
  background: $secondaryColor8;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-inner,
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border-color: $secondaryColor8;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled):after {
  border-color: $secondaryColor8;
}

.ant-checkbox-checked:after {
  border-color: $secondaryColor8;
}

.ant-popover-content {
  max-width: 20rem;
}

a {
  color: #455a64 !important;
}

p,
h1,
h2,
h3 {
  margin-block-start: 0;
  margin-block-end: 0;
}

.edit-article-modal {
  .ant-modal-body {
    margin: 30px 0px;
  }

  .ant-table-title {
    padding: 0px !important;
    border: none !important;
  }
  .ant-table-tbody .ant-table-cell {
    padding: 0px !important;

    text-align: center;
  }

  .ant-form-item-control-input,
  .ant-input,
  .ant-form-item-control-input-content {
    height: inherit;
    margin: 0px;
    border-radius: 0px;
  }

  input {
    border: 1px solid $whiteColor;
  }
  input:hover,
  input:focus,
  input:active {
    border: 1px solid $secondaryColor3;
    box-shadow: none;
  }
}

.tag-line {
  display: block;
  font-size: 22px;
  font-weight: 400;
  text-align: center;
  font-family: monospace;
  color: $secondaryColor8;
  margin: 0;
}

.highlight-text {
  background: $secondaryColor8;
  font-weight: 500;
  color: $whiteColor;
  font-family: monospace;
  padding-left: 1rem;
  padding-right: 1rem;
}

.ant-layout-sider {
  box-shadow: 1px 0 0px 0px rgb(19 22 27 / 8%);
  height: 90vh;

  background: $whiteColor !important;
}
.ant-input-affix-wrapper {
  height: 28px;
}

.ant-input-affix-wrapper:hover {
  border-color: $secondaryColor5 !important;
}

.ant-dropdown .ant-dropdown-menu {
  border-radius: 4px;

  .anticon {
    font-size: 14px;
  }
  .ant-dropdown-menu-item {
    font-size: 12px;
    padding: 5px 10px;
  }
}

.ant-table-wrapper
  .ant-table:not(.ant-table-bordered)
  .ant-table-tbody
  > tr.ant-table-row.ant-table-row-selected
  > td:first-child {
  border-start-start-radius: 0px;
  border-end-start-radius: 0px;
}

.ant-table-wrapper
  .ant-table:not(.ant-table-bordered)
  .ant-table-tbody
  > tr.ant-table-row.ant-table-row-selected
  > td:last-child {
  border-start-end-radius: 0px;
  border-end-end-radius: 0px;
}

.ant-table-content table {
  table-layout: auto !important;
}
// custom css classes

.pointer {
  cursor: pointer;
}
.flex-grow {
  flex-grow: 1;
}
.text-center {
  text-align: center !important;
}

.flex {
  display: flex;
}

.link {
  color: $linkColor !important;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.ant-select,
.ant-select-dropdown,
.flag {
  font-size: 14px;
  margin-left: 1px;
  margin-right: 8px;
}

.ant-switch.ant-switch-checked {
  background: $successColor !important;
}

.ant-notification {
  margin-inline-start: 12px !important;
  bottom: 0px !important;
  .ant-notification-notice {
    padding: 10px 40px 8px 10px;
    border-radius: 0px;

    .ant-notification-notice-close {
      inset-inline-end: 10px;
      top: 10px;
      color: $whiteColor !important;
    }

    .ant-notification-notice-icon {
      font-size: 18px;
      margin-top: 4px;
      color: $whiteColor !important;
    }
    .ant-notification-notice-message {
      margin-bottom: 2px;
      font-weight: 500;
      margin-inline-start: 30px;
      color: $whiteColor;
    }
    .ant-notification-notice-description {
      color: $whiteColor;

      margin-inline-start: 30px;
      line-height: 1.2;
    }
  }
  .ant-notification-notice-error {
    background-color: rgba($warningColor, 80%);
  }
  .ant-notification-notice-warning {
    background-color: $primaryColor2;
  }
  .ant-notification-notice-success {
    background-color: $successColor2;
  }
}
