@import "../../../sassStyles/variables";

.settings .content .orders-config {
  .panel-header {
    h1 {
      font-size: 20px;
      font-weight: 400;
      margin-bottom: 10px;
    }
    p {
      font-size: 14;
    }
  }
  .pricing {
    .ant-form {
      margin: auto;
      margin-top: 10px;
      text-align: left;

      .ant-form-item {
        margin-bottom: 20px;
      }
      .ant-form-item-label {
        padding-bottom: 0px;
      }
      .ant-form-item-label > label {
        color: $textColor;
        font-size: 14px;
      }
      .actions {
        margin-top: 20px;
      }
      .ant-btn {
      }
      .ant-input {
        padding: 10px 10px;
      }
    }
  }

  .bcc-emails {
    .dynamic-delete-button {
      position: relative;
      top: 4px;
      margin: 0 8px;
      color: #999;
      font-size: 24px;
      cursor: pointer;
      transition: all 0.3s;
    }
    .dynamic-delete-button:hover {
      color: #777;
    }
    .dynamic-delete-button[disabled] {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}
