@import "../../../sassStyles/variables";

.guideline {
  background-color: $secondaryColor;
  padding: 40px 80px;
  height: calc(100vh - 54px);

  .header {
    h1 {
      font-size: 30px;
      font-weight: 400;
      margin-bottom: 20px;
    }
    p {
      font-size: 14px;
      color: $textColor2;
      margin-bottom: 20px;
    }
  }

  .ant-divider-horizontal {
    margin: 10px 0;
  }

  .check-point {
    color: $textColor2;
    margin-bottom: 20px;
    font-size: 14px;

    .anticon {
      color: $successColor;
      margin-right: 4px;
    }
    b {
      color: $textColor2;
      font-weight: 700;
    }
  }

  .note {
    margin-top: 20px;
    background-color: $secondaryColor8;
    color: $whiteColor;
    padding: 15px;
    font-size: 14px;
    border-radius: 8px;

    .link {
      text-decoration: underline;
      font-size: 14px;
      color: $primaryColor !important;
    }
  }
}
