// Screen sizes
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
$screen-xxl: 1300px;
$screen-full: 1400px;

// Colors
$textColor: #595959;
$textColor2: #262626;

$whiteColor: #ffff;

$primaryColor: #ffb901;
$primaryColor2: #e6a701;
$primaryColor3: #cc9401;
$primaryColor4: #b38201;

$primaryColorLight: #ffefc6;

$linkColor: #108ee9;
$linkColor2: #448aff;

$secondaryColorLight: #fafafa;
$secondaryColor: #f6f6f6;
$secondaryColor2: #cfd8dc;
$secondaryColor3: #b0bec5;
$secondaryColor4: #90a4ae;
$secondaryColor5: #78909c;
$secondaryColor6: #607d8b;
$secondaryColor7: #546e7a;
$secondaryColor8: #455a64;
$secondaryColor9: #37474f;
$secondaryColor10: #263238;
$secondaryColor11: #1f282c;

$warningColor: red;
$warningLight1Color: #c432571f;

$successColor: #52c41a;
$successColor2: #469a15;
