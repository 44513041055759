@import "../../../sassStyles/variables";

.order-history {
  margin: 20px 0px;
  max-width: 500px;

  .ant-timeline .ant-timeline-item {
    .ant-timeline-item-head-blue {
      color: $primaryColor2;
      border-color: $primaryColor2;
      inset-inline-start: 145px;
    }
    .ant-timeline-item-label {
      width: 130px;
    }
    .ant-timeline-item-content {
      max-width: 500px;
      width: 100%;
      inset-inline-start: 140px;
    }
    .ant-timeline-item-tail {
      inset-inline-start: 145px;
    }
  }
}
