@import "../../sassStyles/variables";
@import "../../sassStyles/mixins";

.auth-container {
  background-color: $secondaryColor;
  height: 100vh;
  display: flex;
}

.verification-code {
  .verification-img {
    margin: auto;
    width: 42rem;
    margin-top: -180px;
    margin-bottom: -15px;
    display: block;
  }

  .auth-logo {
    position: absolute;
    padding-left: 30px;
    padding-top: 20px;

    img {
      width: 85px;
    }
  }

  .form-wrapper-container {
    background: $secondaryColor;
    display: flex;
    justify-content: center;
  }
  .auth-heading {
    margin-top: 24px;
    color: $secondaryColor8;
    font-size: 30px;
    letter-spacing: -1px;
    font-weight: 400;
  }
  .form-wrapper-content {
    border-radius: 1rem;
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
    text-align: center;

    @include lgMinBreakPoint {
      width: 22rem;
      padding: 1rem 2em;

      box-shadow: 0px 0px 20px 4px rgb(0 0 0 / 20%);
      background-color: $whiteColor;
      backdrop-filter: blur(5px);
    }

    .branding {
      padding-top: 0px;
      // width: 140px;
      margin: auto;
      p {
        font-size: 10px;
      }
    }
  }
  .auth-content-container {
    display: grid;
  }

  .auth-content {
    // padding-left: 0rem;
    // padding-top: 0rem;
    // display: block;
    // margin-top: auto;
    // margin-bottom: auto;
    width: 100%;

    @include lgMinBreakPoint {
      padding-top: 4rem;

      margin-top: 0;
      margin-bottom: 0;
      display: grid;
    }
  }
  .countdown {
    margin: 24px 0px;
    color: $secondaryColor7;
  }
  .auth-heading {
    margin-bottom: 14px;
  }
  .ant-btn-primary:disabled {
    background-color: $secondaryColor !important;
    color: $secondaryColor3;
  }
  .verify-btn {
    width: 100%;
    height: 42px;
    font-size: 18px;
    padding: 0;
  }

  .welcome-name {
    font-size: 14px;
    font-weight: 400;
    color: $secondaryColor7;
  }

  .lock-icon {
    font-size: 44px;
    color: $primaryColor2;
    margin-top: 20px;
  }

  .auth-heading {
    margin-top: 14px;
  }
  .verification-code-wrapper {
    display: flex;
    justify-content: center;
    margin: 36px 0px;

    .container {
      width: 350px;
      height: 60px;
    }
    .character {
      border-radius: 3px;
      border-color: $secondaryColor;
      background-color: $secondaryColor;
      color: $secondaryColor5;
      font-size: 38px;
      font-weight: 500;
      line-height: 54px;
    }

    .character--selected {
      outline: 2px solid $primaryColor;
    }
  }
}
